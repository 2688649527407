<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="20" :offset="2">
        <el-card class="box-card">
          <p class="text-2xl border-b-2 pb-2 mb-3">PCR Request Form</p>
          {{ active }}
          <el-steps
            :active="active"
            simple
            style="margin-top: 20px"
            finish-status="success"
          >
            <el-step title="Requester(s)" />
            <el-step title="Billing" />
            <el-step title="Calendar" />
            <el-step title="Payment" />
          </el-steps>
          <div class="persons" v-if="active == 0">
            <el-empty
              v-if="form.persons.length == 0"
              description="No one seems to be any person on the list. 🧐"
            >
              <el-alert
                title="👋🏻"
                type="warning"
                description="Please add pcr test requesters."
                show-icon
                class="pb-2"
                :closable="false"
              />
              <el-divider />

              <el-button
                class="pt-4"
                type="primary"
                @click="dialogFormVisible = true"
                >Add Person</el-button
              >
            </el-empty>
            <div v-else>
              <el-table
                :data="form.persons"
                class="pb-2 mb-3"
                style="width: 100%"
              >
                <el-table-column prop="name" label="Name" />
                <el-table-column prop="passport_id" label="Passport ID" />
                <el-table-column prop="passport_country" label="Country" />
                <el-table-column prop="date_of_birth" label="Date of birth" />
                <el-table-column prop="birthplace" label="Place of birth" />
                <el-table-column
                  prop="destination_country"
                  label="Destination Country"
                />
                <el-table-column prop="flight_no" label="Flight Number" />
                <el-table-column prop="flight_time" label="Flight Date/Time" />
                <el-table-column>
                  <template #default="scope">
                    <el-button
                      type="danger"
                      size="medium"
                      @click="deleteRow(scope.$index)"
                    >
                      Delete
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-button
                class="mt-4"
                style="width: 100%"
                @click="dialogFormVisible = true"
                >Add Person</el-button
              >
            </div>
            <el-dialog v-model="dialogFormVisible" title="New Person Details">
              <el-form :model="form" label-position="top">
                <el-form-item label="Full Name" :label-width="formLabelWidth">
                  <el-input v-model="addModalData.name" autocomplete="off" />
                </el-form-item>
                <el-form-item
                  label="Passport ID Number"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="addModalData.passport_id"
                    autocomplete="off"
                  />
                </el-form-item>
                <el-form-item
                  label="Country where the passport is registered"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="addModalData.passport_country"
                    autocomplete="off"
                  />
                </el-form-item>
                <el-form-item
                  label="Date of Birth"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="addModalData.date_of_birth"
                    autocomplete="off"
                  />
                </el-form-item>
                <el-form-item
                  label="Place of Birth"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="addModalData.birthplace"
                    autocomplete="off"
                  />
                </el-form-item>
                <el-form-item
                  label="Destionation Country"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="addModalData.destination_country"
                    autocomplete="off"
                  />
                </el-form-item>
                <el-form-item
                  label="Flight Number"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="addModalData.flight_no"
                    autocomplete="off"
                  />
                </el-form-item>
                <el-form-item
                  label="Flight Date/Time"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    type="datetime"
                    v-model="addModalData.flight_time"
                    autocomplete="off"
                  />
                </el-form-item>
              </el-form>

              <template #footer>
                <span class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >Cancel</el-button
                  >
                  <el-button type="primary" @click="addDataToPersons()"
                    >Confirm</el-button
                  >
                </span>
              </template>
            </el-dialog>
            <el-button
              v-if="form.persons.length > 0"
              style="margin-top: 12px"
              @click="next"
              >Next step</el-button
            >
          </div>
          <div class="billing mt-4" v-else-if="active == 1">
            <el-form
              ref="form"
              :model="form.billing"
              label-width="auto"
              label-position="top"
              size="default"
            >
              <el-form-item label="Full name">
                <el-input v-model="form.billing.name" />
              </el-form-item>
              <el-form-item>
                <el-form-item class="pr-4" label="Phone Number">
                  <el-col :span="30">
                    <MazPhoneNumberInput
                      v-model="form.billing.phoneNumber"
                      @update="form.billing.results = $event"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item label="E-mail">
                  <el-col :span="30">
                    <el-input v-model="form.billing.email" />
                  </el-col>
                </el-form-item>
              </el-form-item>

              <el-form-item label=" Street Address">
                <el-input v-model="form.billing.address" type="textarea" />
              </el-form-item>
              <el-form-item>
                <el-form-item label="City" class="pr-4">
                  <el-input v-model="form.billing.city" type="text" />
                </el-form-item>
                <el-form-item label=" Country">
                  <el-input v-model="form.billing.country" type="text" />
                </el-form-item>
              </el-form-item>

              <el-form-item>
                <el-form-item label="Passport Expiry Date">
                  <el-date-picker
                    v-model="form.billing.date1"
                    type="date"
                    placeholder="Pick a date"
                    style="width: 100%"
                    class="pr-4"
                  />
                </el-form-item>

                <el-form-item label="Passport ID">
                  <el-input v-model="form.billing.passport_id" />
                </el-form-item>
              </el-form-item>
            </el-form>
            <el-button
              v-if="form.persons.length > 0"
              style="margin-top: 12px"
              @click="next"
              >Next step</el-button
            >
            <el-button v-if="active > 0" style="margin-top: 12px" @click="back"
              >Back step</el-button
            >
          </div>
          <div class="reservation" v-else-if="active == 2">
            <el-form>
              <div v-if="form.booking.date == null" class="day-selection">
                {{ radio1 }}
                <p class="text-xl">Select the Reservation Date</p>
                <el-row :gutter="1">
                  <el-col
                    :span="6"
                    v-for="day in days"
                    :key="days.indexOf(day)"
                    class="border"
                  >
                    <div class="pb-4 pt-4">
                      {{ new Date(day).toLocaleDateString() }}
                      <br />
                      <el-radio
                        v-model="form.booking.date"
                        :label="new Date(day).toLocaleDateString()"
                        size="large"
                        border
                        class=""
                      >
                        Select
                      </el-radio>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div v-else class="other-selection pt-2">
                <el-descriptions title="Reservation Info">
                  <el-descriptions-item label="Date"
                    >{{ form.booking.date }}
                    <el-button
                      @click="form.booking.date = null"
                      size="small"
                      type="danger"
                    >
                      Change the Date
                    </el-button></el-descriptions-item
                  >
                  <el-descriptions-item label="Time"
                    >{{ form.booking.time }}
                  </el-descriptions-item>
                  <el-descriptions-item label="Location">{{
                    form.booking.location
                  }}</el-descriptions-item>
                  <el-descriptions-item label="Persons">
                    <el-tag size="small">{{ form.persons.length }}</el-tag>
                  </el-descriptions-item>
                </el-descriptions>

                <p class="text-xl pt-3">Select the Applicate Time Range</p>
                <el-row :gutter="1">
                  <el-col :span="8">
                    <el-radio
                      v-model="form.booking.time"
                      label="08:30 AM - 12:30 PM"
                      size="large"
                      border
                      class=""
                      >08:30 AM - 12:30 PM</el-radio
                    >
                  </el-col>
                  <el-col :span="8">
                    <el-radio
                      v-model="form.booking.time"
                      label="12:30 PM - 5:30 PM"
                      size="large"
                      border
                      class=""
                      >12:30 PM - 5:30 PM</el-radio
                    >
                  </el-col>
                  <el-col :span="8">
                    <el-radio
                      v-model="form.booking.time"
                      label="5:30 PM - 10:00 PM"
                      size="large"
                      border
                      class=""
                      >5:30 PM - 10:00 PM</el-radio
                    >
                  </el-col>
                </el-row>
                <br />
                <p class="text-xl pt-3">Select the Applicate Location</p>
                <el-radio-group v-model="form.booking.location" size="large">
                  <el-radio-button label="Fatih" />
                  <el-radio-button label="Sultanahmet" />
                  <el-radio-button label="Taksim" />
                  <el-radio-button label="Beyoğlu" />
                </el-radio-group>
                <el-row class="flew justify-center pt-4">
                  <el-col :span="30">
                    <el-form-item label="Hotel Name">
                      <el-input
                        v-model="form.booking.hotel.name"
                        class="w-50 m-2 pr-4"
                        size="large"
                        placeholder="Please Input"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="30">
                    <el-form-item label="Room Number">
                      <el-input
                        v-model="form.booking.hotel.room"
                        class="w-50 m-2"
                        size="large"
                        placeholder="Please Input"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-form>
            <el-button
              v-if="form.persons.length > 0"
              style="margin-top: 12px"
              @click="next"
              >Next step</el-button
            >
            <el-button v-if="active > 0" style="margin-top: 12px" @click="back"
              >Back step</el-button
            >
          </div>
          <div class="payment" v-else-if="active == 3">
            sadsad
            <el-button
              v-if="form.persons.length > 0"
              style="margin-top: 12px"
              @click="next"
              >Next step</el-button
            >
            <el-button v-if="active > 0" style="margin-top: 12px" @click="back"
              >Back step</el-button
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      days: [],
      active: 0,
      dialogFormVisible: null,
      formLabelWidth: "140px",
      value: null,
      form: {
        persons: [],
        billing: {
          phoneNumber: null,
          results: null,
        },
        booking: {
          date: null,
          timeRange: null,
          location: null,
          hotel: {
            name: null,
            room: null,
          },
        },
      },
      addModalData: {
        name: "",
        region: "",
      },
    };
  },
  methods: {
    addDataToPersons() {
      this.form.persons.push({
        name: this.addModalData.name,
        passport_id: this.addModalData.passport_id,
        passport_country: this.addModalData.passport_country,
        date_of_birth: this.addModalData.date_of_birth,
        birthplace: this.addModalData.birthplace,
        destination_country: this.addModalData.destination_country,
        flight_no: this.addModalData.flight_no,
        flight_time: this.addModalData.flight_time,
      });
      this.dialogFormVisible = false;
    },

    deleteRow(index) {
      console.log(index);
      console.log(this.form.persons);
      this.form.persons.splice(index, 1);
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    back() {
      console.log(this.active);
      if (this.active > 0) this.active--;
    },
  },
  created() {
    var date1 = new Date(Date.now());
    var date2 = new Date(Date.now() + 48000 * 1000 * 24);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    for (var i = 0; i < Difference_In_Days; i++) {
      var d = new Date();
      d.setDate(date1.getDate() + i + 1);
      this.days.push(d);
    }
  },
};
</script>
<style scoped>
.el-alert {
  margin: 20px 0 0;
}
.el-alert:first-child {
  margin: 0;
}
</style>
